.error-page {
  height: 100vh;
}
.error-page .dialog {
  padding-top: 200px;
}
.error-page .logo {
  margin: 0 auto 40px auto;
  padding-top: 9px;
  cursor: pointer;
}
.error-page .logo img {
  margin: auto;
}
.error-page .error-message {
  text-align: center;
}
.error-page h1 {
  font-size: 100%;
  line-height: 1.5em;
}
.error-page p {
  font-size: 80%;
}